import { MoonIcon, SunIcon } from "@chakra-ui/icons"
import {
  Box,
  IconButton,
  keyframes,
  Stack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react"
import React from "react"

const translate = keyframes`
  0%  {transform: scale(1);}
  50%  {transform: scale(1.1);}
  100%  {transform: scale(1));}
`

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const iconColor = useColorModeValue("yellow.500", "teal.600")
  const iconBgColor = useColorModeValue("blackAlpha.50", "whiteAlpha.100")
  const gradientBg = useColorModeValue(
    "linear(yellow.200 0%,  yellow.300 75%, orange.300 90%)",
    "linear(to-tr, green.200, pink.500)"
  )
  const animation = `${translate} infinite 4s `
  return (
    <Box as="header" mb="6">
      <Box m="0 auto" maxW="960" px="6" py="5">
        <Stack justify="flex-end" align="center" flexDirection="row">
          <Box position="relative">
            <Box
              top={0}
              bottom={0}
              left={0}
              right={0}
              position="absolute"
              bgGradient={gradientBg}
              filter="blur(10px)"
              animation={animation}
            />
            <IconButton
              position="relative"
              _focus={{ outline: "none" }}
              _hover={{ bgColor: iconBgColor }}
              variantColor="link"
              color={iconColor}
              bgColor={iconBgColor}
              aria-label="Toggle color mode"
              size="lg"
              opacity={colorMode === "dark" ? 0.5 : 0.5}
              icon={colorMode === "dark" ? <MoonIcon /> : <SunIcon />}
              onClick={toggleColorMode}
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

export default Header
